import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react'

import anime from 'animejs';

const Wrapper = styled.div` 
//   max-height:145vh;
   width:45vw;
   position:absolute;
   right:0;
   top:0;
   filter: blur(0.25rem);
`

const BackgroundDecorationScroll = keyframes`
  from {
    transform: translateY(0) 
  }

  to {
    transform: translateY(-200vh) 
  }
`
    
const HeroVerticalTextDecoration = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Teko", sans-serif;
  animation-name: ${BackgroundDecorationScroll};
  animation-duration: 20s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 8s;
  font-size: 2rem;
`

const HorizontalLine = styled.div`
  width:100%;
  height:1px;
  background-color:white;
`

const LineFiller = ({ number }) => {
  return  (
    Array.apply(null, Array(number)).map((i)=>
      <div key={i}>--</div>
      )
  );
}

const LeftLine = ({ text }) => {
  const Line = styled.div` 
    display: flex;
    justify-content:flex-start;
  `

  return (
    <Line>
      <span>--</span> <span>{text}</span>
    </Line>
  )
}

const RightLine = ({ text }) => {
  const Line = styled.div` 
    display: flex;
    justify-content:space-between;
  `

  return (
    <Line>
      <span>--</span> <span>{text}</span>
    </Line>
  )


}


// const ScrollingText = ({ data }) => {
const ScrollingText = () => {


  const verticalRef = useRef();

  useEffect(() => {
    anime({
      targets: verticalRef.current,
      opacity: [0, 0.025],
      delay: function() {
        if (!sessionStorage.getItem('warm')) {
          return 8000
        } else {
          return 500
        }
      },
      duration: function() {
        if (!sessionStorage.getItem('warm')) {
          return 8000
        } else {
          return 0
        }
      },
      ease: "easeInQuad"
    })
  }, []);

  return (
    <Wrapper>
      <HeroVerticalTextDecoration ref={verticalRef}>
        <LineFiller number={15} />
        <LeftLine text="D*SIG." />
        <RightLine text="-EV-LOP" />
        <HorizontalLine />
        <LineFiller number={4} />
        <HorizontalLine />
        <LeftLine text="D-SI.GN" />
        <LineFiller number={4} />
        <LineFiller number={2} />
        <RightLine text="DEV(LO@" />
        <LineFiller number={1} />
        <RightLine text="EX@ER%MEN^" />
        <LeftLine text="D-5-GN" />
        <HorizontalLine />
        <LineFiller number={2} />
        <HorizontalLine />
        <LeftLine text="#E/IGN" />
        <RightLine text="EX@ER%MENT" />
        <RightLine text="E$P!RIM7NT" />
        <LineFiller number={15} />
      </HeroVerticalTextDecoration>
    </Wrapper>
  )

}

export default ScrollingText;