import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from '@emotion/styled';

import { mq } from '../../_shared/media';

import HexagonSVG from './hexagon.svg'
import HexagonWallSVG from './hexagon_wall.svg'

import anime from 'animejs';


const Hexagon = styled(HexagonSVG)` 
    height: 10rem;
    width: auto;
    stroke: var(--primary-color);
    stroke-width: 0.5rem;
    fill: transparent;
    opacity: 0.25;
    /* filter: blur(0.1rem); */

`


const Wrapper = styled.div`
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;

  display: grid;
  place-content: center;
`

const HexagonWall = styled.div`

  & svg {
    position: absolute;
    top: -10%;
    left: 0;

    ${mq.lt.sm} {
      width: 100vw;
    }

    width: 50vw;
    height: 140vh;

    stroke: rgba(255,255,255,0.05);

  }

  & path {
    opacity: 0;
    /* filter: blur(3px); */
  }
`


const Hexagons = () => {

  function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min) ) + min;
  }

  const wallRef = useRef();
  const hexRef = useRef();

  useEffect(() => {

    var hexWallTl = anime.timeline({
      targets: wallRef.current.querySelectorAll('path'),
      easing: 'easeOutExpo',
      loop: true,
      autoplay: false,
      direction: 'forwards'
    });
    
    hexWallTl
    .add({
      opacity: [0, 0],
      duration: 100,
    })
    .add({
      easing: 'easeInOutCirc',
      delay: anime.stagger(1000, {grid: [4, 11], from: 'first'}),
      duration: 5000,
      opacity: [
        {value: 0},
        {value: 1},
        {value: 0},
      ]
    })
    .add({
      opacity: [0, 0],
      duration: getRndInteger(1000,2000),
    })

    if (!sessionStorage.getItem('warm')) {
      setTimeout(function() {
        hexWallTl.play()
      }, 8000)
    } else {
      hexWallTl.play()
    }



    var singleHexTl = anime.timeline({
      targets: hexRef.current.querySelectorAll('svg'),
      easing: 'easeOutExpo',
      loop: false,
      autoplay: false,
      direction: 'forwards'
    });

    singleHexTl
    .add({
      begin: function() {
        hexRef.current.style.transform = 'translate(' + getRndInteger(-45,45) + 'vw, ' + getRndInteger(-45,45) + 'vh)';
      },
      easing: 'linear',
      opacity: [0, 0.5],
      scale: [0.5, 1.5],
      duration: 8000
    })
    .add({
      easing: 'linear',
      opacity: [0.5, 0],
      duration: 1000
    }, "-=1000");

    setInterval(function() {
      singleHexTl.play()
    }, 8000)


  }, [])

  return (
    <Wrapper>
      
      <HexagonWall ref={wallRef}>
        <HexagonWallSVG />
      </HexagonWall>


      <div ref={hexRef}>
        <Hexagon  />
      </div>

      
    </Wrapper>
  )

}

export default Hexagons;