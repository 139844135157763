import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react'
import anime from 'animejs';

const Wrapper = styled.div`
  position: absolute;
  top:60%;
  width: 200vw;
  display:flex;
  flex-direction:row;

  justify-content: start;
  font-size: 3rem;
  filter: blur(0.12rem);
  z-index: -1;
`

const Item = styled.div`
  display: grid;
  place-content: center;
  width:10rem;
  height:4rem;
  text-align:center;
  border-top: solid 0.1rem white;
  border-right: solid 0.25rem lightgrey;
  padding: 4rem;
`

const ItemInner = styled.div`
  width: 1.5rem;
  height: 1rem;
  border: solid 0.25rem;
  border-bottom: none;
`



const HorizontalElements = (props) => {

  const itemRef = useRef();
  const wrapperRef = useRef();

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const getDimensions = () => ({
      width: (itemRef && itemRef.current.offsetWidth) || 0,
      height: (itemRef && itemRef.current.offsetHeight) || 0,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (itemRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // console.log(dimensions.width * -1)
  
  useEffect(() => {
    anime({
      targets: wrapperRef.current,
      translateX: [0, (dimensions.width * -1)],
      duration: 7500,
      easing: "linear",
      loop: true
    })
  });

  useEffect(() => {
    anime({
      targets: wrapperRef.current,
      opacity: [0, 0.025],
      delay: 500,
      duration: function() {
        if (!sessionStorage.getItem('warm')) {
          return 8000
        } else {
          return 0
        }
      },
      easing: "easeInQuad"
    })
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <Item ref={itemRef}>
        <ItemInner/>
      </Item>
      <Item>
        <ItemInner/>
      </Item>
      <Item>
        <ItemInner/>
      </Item>
      <Item>
        <ItemInner/>
      </Item>
      <Item>
        <ItemInner/>
      </Item>
      <Item>
        <ItemInner/>
      </Item>
      <Item>
        <ItemInner/>
      </Item>
      <Item>
        <ItemInner/>
      </Item>
      <Item>
        <ItemInner/>
      </Item>
      <Item>
        <ItemInner/>
      </Item>
    </Wrapper>
  )

}

export default HorizontalElements;