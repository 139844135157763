import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';

import anime from 'animejs';



const Cursor = styled.span`
    position: absolute;
    top: 0;
    left:0;
    bottom: 0.25em;
    width: 0.25em;
    /* background: #f5f5f5; */
    border-bottom: solid 0.05em var(--title-color);
    z-index: 1;
`


const AnimatedHeading = (props) => {

   // const titleRef = useRef(null);
   const lettersRef = useRef(null);
   const cursorRef = useRef(null);
 
   // const [ titleElement, setTitleElement ] = useState(null);
   // const [ lettersHtml, setLettersHtml ] = useState(null);
   const [ titleLetters, setTitleLetters ] = useState(null);


   function trans2() {
       var title = [];
       [...lettersRef.current.children].forEach((letter, i) => {
        //  console.log(letter.offsetLeft)
 
         title[i] = {
             value: letter.offsetLeft + letter.offsetWidth,
             duration: 1,
             delay: i === 0 ? 0 : JUMP_AFTER_MS,
           };
 
       })
      //  console.log(title)
       return title
     }
     
   const TYPE_AFTER_MS = 3000;
   const JUMP_AFTER_MS = 250;



    useEffect(() => {
     var warm = sessionStorage.getItem('warm');
     if(!warm) {
        // flash on load
        anime({
          targets: "#HeroOuter",
          easing: 'steps(1)',
          width: "48px",
          height: "48px",
          loop: 3,
          duration: 300,
          opacity: [
            {value: 0},
            {value: 1},
          ],
        });
    
        anime({
          targets: "#ScrollIndicator",
          opacity: [0, 1],
          delay: 6000,
          duration: 3000,
          ease: "easeOutQuad"
        });
    
        const blink = anime({
          targets: cursorRef.current,
          loop: 8,
          easing: 'steps(1)',
          duration: 750,
          opacity: [
            {value: 1},
            {value: 0}
          ],
        });
    
        //reveal
    
        const bigRevealAnimation = anime.timeline({delay: 0})
        bigRevealAnimation.add({
          targets: "#HeroOuter",
          height: "60vh",
          easing: "spring",
          duration: 750
        }, 1000)
        .add({
          targets: "#HeroOuter",
          width: "80vw",
          easing: "spring",
          duration: 750,
        }, "-=1000")
        .add({
          targets: "#HeroInner",
          opacity: [0, 1],
        }, "-=1000")
        .add({
          targets: "#MainTitle",
          opacity: [0, 1],
          duration: 10
        }, "-=1000")
        // .add({
        //   targets: "#MainTitle",
        //   scaleY: [0, 1],
        //   scaleX: [2, 1]
        // }, "-=1000")
        .add({
          targets: cursorRef.current,
          translateX: trans2()
        }, TYPE_AFTER_MS)
        .add({
          targets: '#MainTitle .letter',
          opacity: [0, 1],
          duration: 200,
          easing: "easeOutExpo",
          transformOrigin: ["50% 100%", "50% 100%"],
          scaleY: [0, 1],
          delay: anime.stagger(JUMP_AFTER_MS),
          changeBegin: () => {
            blink.reset();
            blink.pause();
          },
          changeComplete: () => {
            blink.restart();
          }
        }, TYPE_AFTER_MS)
        .add({
          targets: ".hero--subtitle:nth-child(2)",
          opacity: [0, 1],
          scaleY: [0, 1],
          scaleX: [4, 1],
          easing: "easeOutQuad",
          duration: 120
    
        }, "+=1000")
        .add({
          targets: ".hero--subtitle:not(:nth-child(2))",
          opacity: [0, 1],
          delay: anime.stagger(500)
        }, "+=1000")
        .add({
          targets: "#Taglines div",
          opacity: [0, 1],
          delay: anime.stagger(800)
        })
        .add({
          targets: "#Taglines div:nth-child(1)",
          scaleY: [0, 1],
          ease: "linear",
          complete: function() {
            sessionStorage.setItem('warm', '1');
          }
        });
   
      } else {
        //already played once
        anime({
          targets: cursorRef.current,
          duration: 1,
          opacity: 0,
        });

      }


     }, []);

   
 

   return (
    <div style={{position: "relative"}}> 
      {/* <div ref={el => { console.log([...el.children]); lettersRef.current = [...el.children]; }} className="letters"> */}
      <div ref={lettersRef}> 
        {props.children}
      </div>
      <Cursor ref={cursorRef}></Cursor>
    </div>
    )
}


export default AnimatedHeading;