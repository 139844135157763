import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from '@emotion/styled';

import anime from 'animejs';



const Wrapper = styled.div`
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;

  display:grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 5vw;

  & div {
    opacity: 0;
    display: grid;
    place-content: center;
    font-size: 2rem;
  }
`



const Plusdots = ( { dots }) => {

  function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min) ) + min;
  }

  const wrapperRef = useRef();
  // console.log(wrapperRef.current.children[0])
  // plusdots[Math.floor(Math.random() * plusdots.length + 1)];
  
  useEffect(() => {
    var plusdots = wrapperRef.current.querySelectorAll('div')
    setInterval(function() {
      anime({
        targets: plusdots[getRndInteger(0, plusdots.length)],
        easing: 'easeOutExpo',
        duration: getRndInteger(100, 400),
        delay: getRndInteger(300, 800),
        direction: 'alternate',
        opacity: [0, 0.25],
        begin: function(anim) {
          anim.animatables[0].target.style.transform = 'translate(' + getRndInteger(1,20) + 'px, ' + getRndInteger(1,20) + 'px)';
        }
      });
    }, getRndInteger(1000, 2000))

    setInterval(function() {
      anime({
        targets: plusdots[getRndInteger(0, plusdots.length)],
        easing: 'steps(1)',
        loop: 1,
        duration: 300,
        opacity: [
          {value: 0},
          {value: 0.75},
          {value: 0}
        ],
      });
    }, getRndInteger(3000, 5000))


  }, [])

  return (


    <Wrapper ref={wrapperRef}>
      {Array.apply(null, Array(dots)).map((i)=>
        <div key={i}>+</div>
      )}
    </Wrapper>
  )

}

export default Plusdots;