import styled from '@emotion/styled';
import { jsx, css, keyframes } from '@emotion/react'
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import ScrollIndicator from './scroll-indicator';
import AnimatedHeading from './animated-heading';



//bg deco
import ScrollingText from './background-decorations/scrolling-text'
import HorizontalElements from './background-decorations/horizontal-elements'
import Plusdots from './background-decorations/plusdots'
import Hexagons from './background-decorations/hexagons/hexagons';

import { mq } from './_shared/media';
import { StyledSection } from './_shared/styled-section';

// import anime from 'animejs';

// import { uuid } from 'uuid4';

const FadeAway = keyframes`
  to {
    opacity: 0
  }
`

const textShadow = keyframes`
  0% {
    text-shadow: 0.4389924193300864px 0 1px rgba(0,30,255,0.5), -0.4389924193300864px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  5% {
    text-shadow: 2.7928974010788217px 0 1px rgba(0,30,255,0.5), -2.7928974010788217px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  10% {
    text-shadow: 0.02956275843481219px 0 1px rgba(0,30,255,0.5), -0.02956275843481219px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  15% {
    text-shadow: 0.40218538552878136px 0 1px rgba(0,30,255,0.5), -0.40218538552878136px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  20% {
    text-shadow: 3.4794037899852017px 0 1px rgba(0,30,255,0.5), -3.4794037899852017px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  25% {
    text-shadow: 1.6125630401149584px 0 1px rgba(0,30,255,0.5), -1.6125630401149584px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  30% {
    text-shadow: 0.7015590085143956px 0 1px rgba(0,30,255,0.5), -0.7015590085143956px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  35% {
    text-shadow: 3.896914047650351px 0 1px rgba(0,30,255,0.5), -3.896914047650351px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  40% {
    text-shadow: 3.870905614848819px 0 1px rgba(0,30,255,0.5), -3.870905614848819px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  45% {
    text-shadow: 2.231056963361899px 0 1px rgba(0,30,255,0.5), -2.231056963361899px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  50% {
    text-shadow: 0.08084290417898504px 0 1px rgba(0,30,255,0.5), -0.08084290417898504px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  55% {
    text-shadow: 2.3758461067427543px 0 1px rgba(0,30,255,0.5), -2.3758461067427543px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  60% {
    text-shadow: 2.202193051050636px 0 1px rgba(0,30,255,0.5), -2.202193051050636px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  65% {
    text-shadow: 2.8638780614874975px 0 1px rgba(0,30,255,0.5), -2.8638780614874975px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  70% {
    text-shadow: 0.48874025155497314px 0 1px rgba(0,30,255,0.5), -0.48874025155497314px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  75% {
    text-shadow: 1.8948491305757957px 0 1px rgba(0,30,255,0.5), -1.8948491305757957px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  80% {
    text-shadow: 0.0833037308038857px 0 1px rgba(0,30,255,0.5), -0.0833037308038857px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  85% {
    text-shadow: 0.09769827255241735px 0 1px rgba(0,30,255,0.5), -0.09769827255241735px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  90% {
    text-shadow: 3.443339761481782px 0 1px rgba(0,30,255,0.5), -3.443339761481782px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  95% {
    text-shadow: 2.1841838852799786px 0 1px rgba(0,30,255,0.5), -2.1841838852799786px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  100% {
    text-shadow: 2.6208764473832513px 0 1px rgba(0,30,255,0.5), -2.6208764473832513px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
`

const StyledHeroContainer = styled.div`
  position: relative;

  /* &:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(18, 16, 16, 0.1);
    opacity: 0;
    z-index: 2;
    pointer-events: none;
    animation: ${FadeAway} 4000ms ease-out forwards;
    animation-delay: 8000ms;

  }
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
    z-index: 2;
    background-size: 100% 2px, 3px 100%;
    pointer-events: none;
    animation: ${FadeAway} 4000ms ease-out forwards;
    animation-delay: 8000ms;
  } */
`

// const Styled3D = styled.div`
//   position: absolute;
//   top:0;
//   left:0;
//   width:100%;
//   height:100%;
// `;

const StyledHeroSection = styled(StyledSection)`
  min-height: calc(100vh - 2 * var(--header-height));
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  ${mq.gt.sm} {
    min-height: calc(100vh - var(--header-height));
  }
`;

const NewHeroOuter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  max-width: 800px;
  height: 60vh;
  background:
    linear-gradient(to right, var(--title-color) 4px, transparent 4px) 0 0,
    linear-gradient(to right, var(--title-color) 4px, transparent 4px) 0 100%,
    linear-gradient(to left, var(--title-color) 4px, transparent 4px) 100% 0,
    linear-gradient(to left, var(--title-color) 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, var(--title-color) 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, var(--title-color) 4px, transparent 4px) 100% 0,
    linear-gradient(to top, var(--title-color) 4px, transparent 4px) 0 100%,
    linear-gradient(to top, var(--title-color) 4px, transparent 4px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
`;

const NewHeroInner = styled.div`
  width:100%;
  display:flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Teko', sans-serif;
  overflow: hidden;

  height: 100%;
  justify-content: space-around;
`;

const PartOne = styled.div`
  width:100%;
  display:flex;
  justify-content: space-around;
  font-weight: 900;
`;

const SubTitle = styled.span`
  align-self: end;
  font-size: 1.75rem;
  text-transform: uppercase;
`;

const PartTwo = styled.div`
  margin: 0.5rem;
  font-size: 7rem;
  font-family: 'Teko', sans-serif;
  text-transform: uppercase;
  color: var(--title-color);
  display: grid;
  place-content: center;
  min-width: 80vw;//fix for cursor typing animation mixed with initial loading anim


  ${mq.gt.xs} {
    font-size: 12rem;
  }

  //stuff for typing animation
  position: relative;
  letter-spacing: 1px;
  line-height: 1;

  .letter {
    display: inline-block;
  }

`;



// const Heading = styled.h1`
//   /* margin: 0.5rem;
//   font-size: 4rem;
//   font-family: 'Teko', sans-serif;
//   text-transform: uppercase;
//   color: var(--title-color);

//   ${mq.gt.xs} {
//     font-size: 5rem;
//   } */
// `;

const Heading = ({ author }) => {
  return  (
      author.split('').map((ch) =>
        <span className="letter">{ch}</span>
      )
  );
}

const SubHeading = styled.h2`
  padding: 0;
  margin:0;
  font-size: 2rem;
  color: var(--primary-color);
  animation: ${textShadow} 1.6s infinite;
`;

const PartThree = styled.div`
  display: flex; 
  flex-direction: column;
`;


const BackgroundDecorationWrapper = styled.div`
  overflow-x:clip;
  position:absolute;
  right:0;
  top:0;
  bottom:0;
  left:0;

  z-index:-2;
`


const Hero = ({ data }) => {
  // const { introduction, author, tagline, description, ctaLink, ctaLabel } = data;
  const { author } = data;
  

  return (
    <StyledHeroContainer>
      <StyledHeroSection>
          <NewHeroOuter id="HeroOuter">
              <NewHeroInner id="HeroInner">
                <PartOne>
                  <SubTitle className="hero--subtitle">Design</SubTitle>
                  <SubHeading className="hero--subtitle">実験</SubHeading>
                  <SubTitle className="hero--subtitle">Develop</SubTitle>
                </PartOne>
                <PartTwo id="MainTitle">
                  <AnimatedHeading>
                    <Heading author={author} />
                  </AnimatedHeading>
                </PartTwo>
                <PartThree id="Taglines">
                </PartThree>
              </NewHeroInner>
          </NewHeroOuter>
          <ScrollIndicator />
      </StyledHeroSection>

        <BackgroundDecorationWrapper>

          <ScrollingText />
          <HorizontalElements />
          <Plusdots dots={20} />
          <Hexagons />
          
        </BackgroundDecorationWrapper>

    </StyledHeroContainer>
  );
};

Hero.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Hero;
